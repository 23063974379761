<template>
	<div class="InvoiceRegister">
		<div class="d-flex">
			<v-checkbox label="Inkludera makulerade fakturor" @change="filterCanceledInvoices" />
			<v-spacer />
			<div>
				<div class="d-flex justify-end">
					<v-btn v-if="isUserAllowedToInvoicePrint" v-tooltip :loading="isPending" content="Exportera till excel" color="primary"
						class="mx-4"
						@click="exportExcel"
					>
						<v-icon>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn color="primary" @click="showSearch = true"><v-icon left>mdi-magnify</v-icon>Avancerad sök </v-btn>
				</div>
				<p v-if="hasActiveAdvancedFilters" class="text-caption mt-2">
					Du har ett aktivt urval (med {{ pagination.total }} träffar), klicka <strong class="primary--text text-decoration-underline" style="cursor: pointer;" @click="clearFilter">här</strong> för att rensa.
				</p>
			</div>
		</div>
		<Loader :error="errorMessage" :loading="isPendingReview" />

		<RegisterList
			ref="registerList"
			:items="invoices"
			:headers="getHeaders"
			sidebar-width="65%"
			:mass-actions="massActions"
			:push-content="false"
			item-key="_id"
			:pagination="pagination"
			:show-sidebar="activeInvoice !== null"
			@mass-action="onMassAction"
			@active-item-changed="setActiveItem"
			@pagination-update="updatePagination"
			@on-filter-update="onFilterUpdate"
		>
			<InvoiceSidebar :invoice="activeInvoice" :convo="$route.query.chat" @on-refetch="reFetchInvoices" />
		</RegisterList>

		<Loader :error="errorMessage" :loading="isPendingReview" />

		<AdvancedSearchDialog v-if="showSearch"
			:filter="advancedFilters.raw"
			@reject="showSearch = false"
			@confirm="onAdvancedUpdate"
		/>
		<Dialog v-if="pdfAttachment && pdfDialog" :disable-ok-btn="true" :show-cancel-btn="false" :title="'Skriv ut'"
			:width="900" :alert="true" @reject="closePrintDialog"
		>
			<PdfViewer :pdf-data="pdfAttachment" />
		</Dialog>

		<Dialog v-if="sensitivityDialog"
			:alert="!isUserAllowedToHandleManageSensitiveInvoices"
			title="Denna faktura är sekretessklassad"
			ok-btn-text="Ja"
			@confirm="setActiveSensitivityItem"
			@reject="sensitivityDialog = false"
		>
			<div class="my-8">
				<div v-if="!isUserAllowedToHandleManageSensitiveInvoices">Du saknar behörighet att se denna faktura</div>
				<div v-if="isUserAllowedToHandleManageSensitiveInvoices">
					Fakturan har sekretessklassning <strong>{{ sensitivityLevel }}</strong>, vill du visa faktura?
				</div>
			</div>
		</Dialog>
	</div>
</template>

<script>
import RegisterList from '@/components/register-list';
import InvoiceSidebar from './components/sidebar';
import AdvancedSearchDialog from '@/components/advanced-search/form-dialog';
import base64 from 'base-64';
import {mapGetters} from 'vuex';
import PdfViewer from '../../../../components/pdf-viewer.vue';


export default {
	name: 'InvoicesChildView',

	components: {
		RegisterList,
		InvoiceSidebar,
		AdvancedSearchDialog,
		PdfViewer
	},

	props: {
		isDialog: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		invoices: [],
		includeDeleted: false,

		activeInvoice: null,
		activeInvoiceId: null,

		pdfAttachment: null,

		pdfDialog: false,
		showSearch: false,
		isPendingReview: false,
		isPending: false,
		errorMessage: '',

		pagination: {
			page: 1,
			pageSize: 25,
			total: null,
			sortBy: [],
			sortDesc: [false]
		},

		filter: {},
		advancedFilters: {
			raw: {},
			computed: {}
		},

		sensitivityLevel: '',
		sensitivityDialog: false,
		activeSensitivityInvoice: null,

		massActions: [{
			text: 'Sätt som utredning',
			value: 'review'
		}, {
			text: 'Sätt som utredd ',
			value: 'unreview'
		}, {
			text: 'Skriv ut',
			value: 'print'
		}, {
			text: 'Skriv ut (exkl. sekretess)',
			value: 'print-exkl'
		}]
	}),

	computed: {
		...mapGetters('userStore', ['getUser', 'isUserAllowedToHandleManageSensitiveInvoices', 'isUserAllowedToInvoicePrint']),

		hasActiveAdvancedFilters() {
			return Object.keys(this.advancedFilters.computed).length !== 0;
		},

		getHeaders() {
			return [{
				text: 'ID',
				value: 'ID',
				align: 'start',
				filter: {
					type: 'text'
				}
			}, {
				text: 'Leverantör',
				value: 'Parties.Seller.Name',
				filter: {
					type: 'text',
					placeholder: ''
				}
			}, {
				text: 'Belopp inkl. moms',
				value: 'Amounts.TaxInclusiveTotal.Amount',
				width: 250,
				align: 'left',
				filter: [{
					type: 'text',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(val) : null
				},
				{
					type: 'text',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(val) : null
				}]
			}, {
				text: 'Förfallodatum',
				value: 'Dates.DueDate',
				width: 250,
				filter: [{
					type: 'date',
					placeholder: 'fr.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				},
				{
					type: 'date',
					placeholder: 't.o.m.',
					transform: val => val !== null ? Number(parseInt(val.replaceAll('-', ''))) : null
				}]
			}, {
				text: 'Fakturatyp',
				value: 'meta.document_sub_type',
				filter: {
					type: 'select',
					items: [{
						text: 'Debet',
						value: 'debit'
					}, {
						text: 'Kredit',
						value: 'credit'
					}]
				}
			}, {
				text: 'Status',
				value: 'meta.status',
				filter: {
					type: 'select',
					items: [{
						text: 'Ankomstbokförd',
						value: 'ankomstbokförd'
					}, {
						text: 'Arkiverad',
						value: 'Arkiverad'
					}, {
						text: 'Attesterad',
						value: 'Attesterad'
					}, {
						text: 'Avbokad ankomstbokförd faktura',
						value: 'Avbokad ankomstbokförd faktura'
					}, {
						text: 'Betalningsmarkerad bokförd faktura',
						value: 'Betalningsmarkerad bokförd faktura'
					}, {
						text: 'Definitivt bokförd',
						value: 'Definitivt bokförd'
					}, {
						text: 'Konterad',
						value: 'konterad'
					}, {
						text: 'Makulerad ankomstbokförd faktura',
						value: 'Makulerad ankomstbokförd faktura'
					}, {
						text: 'Makulerad definitivt bokförd faktura',
						value: 'Makulerad definitivt bokförd faktura'
					}, {
						text: 'Registrerad',
						value: 'registrerad'
					}, {
						text: 'Skickad till betalning',
						value: 'Skickad till betalning'
					}, {
						text: 'Spärrad',
						value: 'Spärrad'
					}, {
						text: 'Återredovisad från betalningsinstitut',
						value: 'Återredovisad från betalningsinstitut'
					}]
				}
			}];
		}
	},

	watch: {
		async 'advancedFilters.raw'(to) {
			const query = { ...this.$route.query, filter: base64.encode(JSON.stringify(to)) };

			try {
				await this.$router.replace({ query });
			} catch(error) {
				console.log(error);
			}
		}
	},

	created() {
		this.parseFilterQuery();
	},

	async mounted() {
		if(this.$route.query.supplierInvoice) {
			await this.fetchInvoices();
			const invoice = this.invoices.filter((s) => s._id === this.$route.query.supplierInvoice);

			this.setActiveItem(invoice[0]);
			this.$refs.registerList.open({ _id: this.activeInvoiceId });

			return;
		}

		if (this.$route.query.invoice) {
			this.setActiveItem(this.$route.query.invoice);
			this.$refs.registerList.open({ _id: this.activeInvoiceId });
		}
	},

	methods: {
		parseFilterQuery() {
			if ('filter' in this.$route.query) {
				this.advancedFilters.raw = JSON.parse(base64.decode(this.$route.query.filter));
			}
		},

		async exportExcel() {
			this.isPending = false;
			const data = await this.$service.invoices.search({
				pageSize: 0,
				excel: true,
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					...this.advancedFilters.computed,
					...(!this.includeDeleted ? { 'meta.deleted': false } : {})
				},
				'ids': this.advancedFilters.invoiceIds
			});
			// Fakturanr/OCR-nummer/Dokumenttyp/Fakturatyp/Fakturastatus/Ankomstdatum/Leverantör/Organisationsnr/fakturamottagare/Referenskod/
			// Kategorier av abonnemang/Betaldatum/Belopp inkl. moms/Momsbelopp/Konto/Gironr/Verifikationsnr/Verifikationsnr prefix/Dokumentlöpnr/Sekretessnivå/Spärrad/Under utredning
			// /Attestanter/Grupp/Ansvar/Konto/Verksamhet/Aktivitet/Projekt/Objekt/Fri/Motpart/SystemID
			// const excell = data.map(d => ({
			// 	Fakturanr: d.name,
			// 	'OCR-nummer':'',
			// 	'Dokumenttyp':'',
			// 	'Fakturatyp':'',
			// 	'Fakturastatus':'',
			// 	'Ankomstdatum':'',
			// 	'Leverantör':'',
			// 	'Organisationsnr':'',
			// 	'Fakturamottagare':'',
			// 	'Referenskod':'',
			// 	'Kategorier av abonnemang':'',
			// 	'':'',
			// 	'':'',
			// 	'':'',
			// 	'':'',

			// 	'Öppen fr.o.m.': this.$utils.date.intToPretty(d.open_from),
			// 	'Öppen t.o.m.': this.$utils.date.intToPretty(d.open_to),
			// 	'System ID': d._id
			// }));

			await this.$utils.excel.download(data, 'Alla fakturor');
			this.isPending = false;
		},

		closePrintDialog() {
			this.pdfDialog = false;
			window.location.reload();
		},

		clearFilter() {
			this.advancedFilters = {
				raw: {},
				computed: {}
			};
			this.fetchInvoices();
		},

		filterCanceledInvoices(value) {
			this.includeDeleted = value;
			this.fetchInvoices();
		},

		setActiveSensitivityItem() {
			this.activeInvoice = this.activeSensitivityInvoice;
			this.activeInvoiceId = this.activeSensitivityInvoice && this.activeSensitivityInvoice._id;
			this.sensitivityDialog = false;
		},

		setActiveItem(item) {
			if(item && item.meta.sensitivity > 0) {
				this.sensitivityLevel = item.meta.sensitivity === 1 ? 'utskrift': 'visning';
				this.activeSensitivityInvoice = item;
				this.activeInvoice = null;
				this.sensitivityDialog = true;

				return;
			}

			// if(item.meta.status === 'Definitivt bokförd') {
			// 	this.activePayment = {
			// 		_id: item._id
			// 	};

			// 	return;
			// }
			this.activeInvoice = item;
			this.activeInvoiceId = item && item._id;
		},

		updatePagination(paginationObj) {
			this.pagination = paginationObj;

			this.fetchInvoices();
		},

		onAdvancedUpdate(filterObj) {
			this.showSearch = false;
			this.advancedFilters = filterObj;
			this.fetchInvoices();
		},

		onFilterUpdate(filter) {
			this.showSearch = false;
			this.filter = filter;
			this.fetchInvoices();
		},

		async reFetchInvoices() {
			await this.fetchInvoices();
		},

		onMassAction(data) {
			const invoiceIds = data.items.map(invoice => invoice._id);

			this.setReviewStatus(data.type, invoiceIds);

			if (data.type === 'print' || data.type === 'print-exkl') {
				this.printInvoice(data.type, invoiceIds);
			}
		},

		async setReviewStatus(type, ids) {
			this.errorMessage= '';
			this.isPendingReview = true;
			try {
				type === 'review'?
					await this.$service.invoices.review({
						invoiceIds: [
							...ids
						]
					}) :
					await this.$service.invoices.unreview({
						invoiceIds: [
							...ids
						]
					});
				this.showReviewDialog = false;
			} catch(error) {
				this.errorMessage = error.message;
			}

			await this.fetchInvoices();
			this.isPendingReview = false;
		},

		async printInvoice(type, ids) {
			this.errorMessage = '';
			this.isPendingReview = true;
			try {
				if (type === 'print') {
					this.pdfAttachment = await this.$service.invoices.search({
						ids: [...ids],
						print: true
					}, {
						responseType: 'arraybuffer',
						headers: {
							'Content-Type': 'application/pdf',
							'Accept': 'application/pdf'
						}
					});
				} else if (type === 'print-exkl') {
					this.pdfAttachment = await this.$service.invoices.search({
						ids: [...ids],
						print: true,
						skipSensitive: true
					}, {
						responseType: 'arraybuffer',
						headers: {
							'Content-Type': 'application/pdf',
							'Accept': 'application/pdf'
						}
					});
				}
			} catch (error) {
				this.errorMessage = error.message;
			}
			this.pdfDialog = true;
		},



		async fetchInvoices() {
			const sort = this.$utils.pagination.getSortObj(this.pagination);
			const data = await this.$service.invoices.search({
				page: this.pagination.page,
				pageSize: this.pagination.pageSize,
				...(Object.keys(sort).length ? {sort} : {}),
				filter: {
					...this.$utils.filter.getFilterQuery(this.filter),
					...this.advancedFilters.computed,
					...(!this.includeDeleted ? { 'meta.deleted': false } : {}),
					'meta.source' : { '$nin' : ['manual_payment'] }
				},
				'ids': this.advancedFilters.invoiceIds,
				'projection' : {
					'ID': 1,
					'Dates.DueDate' : 1,
					'Amounts.TaxInclusiveTotal' : 1,
					'Parties.Seller' : 1,
					'meta' : 1
				}
			});

			this.pagination.total = data._paginationTotal;
			this.invoices = data.map(d => ({
				...d,
				Amounts: {
					...d.Amounts,
					TaxInclusiveTotal: {
						...(d.Amounts ? {...d.Amounts.TaxInclusiveTotal} : {}),
						Amount: d?.Amounts?.TaxInclusiveTotal?.Amount ? this.$utils.format.price(d.Amounts.TaxInclusiveTotal.Amount) : null
					}
				},
				_skipInStepper: d.meta.sensitivity && d.meta.sensitivity > 1,
				_document_sub_type: d.meta.document_sub_type === 'debit' ? 'Debet' : 'Kredit'
			}));
		}
	}
};
</script>
