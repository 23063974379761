<template>
	<div>
		<portal to="sidebar-right-title">
			{{ localInvoice.ID }}
			<Loader :error="errorMessage" :loading="isPending" slim />
		</portal>

		<portal to="sidebar-right-actions">
			<div class="d-flex align-center" style="height: 100%;">
				<v-btn v-if="isUserAllowedToInvoiceHandle" v-tooltip fab text
					small
					content="Konversation"
					@click.stop="showConvoDialog = true"
				>
					<v-icon>mdi-chat-outline</v-icon>
				</v-btn>

				<v-btn v-if="isUserAllowedToInvoiceHandle" v-tooltip fab text
					small
					content="Bilagor"
					@click.stop="openPreview(localInvoice.meta.attachments[0].type || '' )"
				>
					<v-icon>mdi-paperclip</v-icon>
				</v-btn>

				<v-btn v-tooltip class="ml-2" fab text
					small
					content="Historik"
					@click.stop="openPreview('history')"
				>
					<v-icon>mdi-history</v-icon>
				</v-btn>

				<div v-if="localInvoice.meta">
					<v-btn v-tooltip class="ml-2" fab text
						small
						:content="`Fakturans sekretessnivå: ${sensitivityLevels[localInvoice.meta.sensitivity]}`"
						:color="localInvoice.meta.sensitivity > 0 ? 'error' : 'background'"
						@click="showChangeSensitivityDialog = true"
					>
						<v-icon v-if="localInvoice.meta.sensitivity > 0">mdi-lock-outline</v-icon>
						<v-icon v-else>mdi-lock-open-outline</v-icon>
					</v-btn>
				</div>

				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn :disabled="!isUserAllowedToInvoiceHandle" class="ml-2" fab text small
							v-bind="attrs"
							v-on="on"
						>
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<v-icon left>mdi-swap-vertical</v-icon> Export/Import excel
							</v-list-item-title>
						</v-list-item>
						<!-- <v-list-item @click="toggleShowCodeTexts">
							<v-list-item-title>
								<v-icon left>{{ showCodeTexts ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon> Visa kodtexter
							</v-list-item-title>
						</v-list-item> -->
						<v-list-item @click="getXml">
							<v-list-item-title>
								<v-icon left>mdi-xml</v-icon> Visa XML
							</v-list-item-title>
						</v-list-item>

						<v-list-item v-if="localInvoice.meta && localInvoice.meta.supplier_id" @click="$router.push({path: '/register/suppliers', query: {
							supplierId: localInvoice.meta.supplier_id,
							invoiceId: localInvoice._id
						}})"
						>
							<v-list-item-title>
								<v-icon left>mdi-truck-delivery</v-icon> Gå till Leverantör
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-else @click="$router.push({ path: '/register/suppliers', query: {
							supplierId: 'new',
							invoiceId: localInvoice._id
						}})"
						>
							<v-list-item-title>
								<v-icon left>mdi-truck-delivery</v-icon> Skapa Leverantör
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="isUserAllowedToInvoiceHandle" @click="showChangeSensitivityDialog = true">
							<v-list-item-title>
								<v-icon left>mdi-lock-outline</v-icon> Ändra Sekretessnivå
							</v-list-item-title>
						</v-list-item>
						<v-list-item @click="paydateDialog = true">
							<v-list-item-title>
								<v-icon left>mdi-calendar-range</v-icon> Ändra betalningsdatum
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="localInvoice.meta && isUserAllowedToInvoiceHandle" @click="showReviewDialog = true">
							<v-list-item-title>
								<v-icon :color="localInvoice.meta.review ? 'error' : ''" left>mdi-file-find-outline</v-icon> {{ localInvoice.meta.review ? 'Utredd' : 'Sätt som utredning' }}
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="isUserAllowedToDeleteAccountedInvoice" :disabled="localInvoice.meta && localInvoice.meta.deleted" @click="showCancelInvoiceDialog = true">
							<v-list-item-title>
								<v-icon left>mdi-cancel</v-icon> Makulera Faktura
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</portal>

		<v-expansion-panels v-model="panel" multiple>
			<v-expansion-panel>
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Fakturauppgifter
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<InvoiceInfo v-if="localInvoice.ID" :todos="todos" :invoice="localInvoice" :giros="supplier.giro" @on-update="fetchInvoice" />
				</v-expansion-panel-content>
			</v-expansion-panel>

			<InvoiceChecklist v-if="localInvoice.ID" :invoice="localInvoice" @checklist-updated="fetchInvoice" />

			<v-expansion-panel v-if="definitiveStatus">
				<v-expansion-panel-header class="text-button" style="line-height: 100%;">
					Betalning
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<payment v-if="localInvoice.ID" :invoice="localInvoice" @on-update="fetchInvoice" />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<InvoiceAccounting v-if="localInvoice.ID" class="mt-4" :disabled="localInvoice.meta.deleted"
			:amount-left="amountLeft"
			:supplier="supplier"
			:invoice="localInvoice"
			:show-code-texts="showCodeTexts"
			@accounting:updated="fetchInvoice"
		/>

		<invoice-preview v-if="previewOpen" :key="`${activeType}-${localInvoice._id}`" width="35%" :default-view="activeType" :invoice="localInvoice"
			@on-close="closePreview"
		/>

		<Dialog v-if="showXml" alert title="XML" :disable-ok-btn="true" width="960"
			@reject="showXml = false"
		>
			<Xml :data="xml" />
			<Loader :service="[$service.invoices.getXml]" hide-loading slim />
		</Dialog>

		<sensitivity-dialog v-if="showChangeSensitivityDialog"
			:invoice="localInvoice"
			@reject="showChangeSensitivityDialog = false"
			@on-update="onStatusUpdate"
		/>

		<cancel-invoice-dialog v-if="showCancelInvoiceDialog"
			:invoice="localInvoice"
			@reject="showCancelInvoiceDialog = false"
			@on-update="fetchInvoice"
		/>

		<convoDialog v-if="showConvoDialog"
			width="35%"
			:mail="mail"
			:invoice="localInvoice"
			@close="showConvoDialog = false"
			@updated="setUpdatedConvo"
			@leave-chat="showConvoDialog = false"
		/>

		<Dialog v-if="showReviewDialog" :title="`Sätt som ${localInvoice.meta.review ? 'utredd': 'under utredning'}`"
			@reject="showReviewDialog = false"
			@confirm="setReviewStatus"
		>
			<div class="py-6 mb-6">
				{{ `Vill du sätta ${localInvoice.meta.bookings.arrival[0].meta.invoice_name} som ${localInvoice.meta.review ? 'utredd': 'under utredning'}?` }}
			</div>
			<Loader :error="errorMessage" :loading="isPendingReview" slim />
		</Dialog>

		<Dialog v-if="paydateDialog" title="Ändra betalningsdatum på faktura"
			@reject="paydateDialog = false"
			@confirm="changePaydate"
		>
			<label class="text-subtitle-2 font-weight-medium"> Betalningsdatum</label>
			<ui-datepicker v-model="payDate" class="pr-0" no-label
				clearable
			/>
			<Loader :error="paydateError" />
		</Dialog>
	</div>
</template>

<script>

import base64 from 'base-64';
import Dialog from '@/components/dialog';
import InvoiceInfo from './info';
import Xml from './xml';
import InvoiceAccounting from './accounting';
import InvoiceChecklist from './checklist';
import {run} from '@/utils/service';
import pako from 'pako';
import invoicePreview from '@/components/invoice-preview';
import sensitivityDialog from './sensitivity-dialog';
import cancelInvoiceDialog from './cancel-dialog';
import convoDialog from '@/views/inbox/children/conversations/components/chat';
import Payment from '@/views/payments/children/awaiting-payments/components/payment';
import {mapGetters} from 'vuex';

export default {
	name: 'InvoiceSidebar',

	components: {
		Dialog,
		InvoiceInfo,
		InvoiceAccounting,
		Xml,
		InvoiceChecklist,
		sensitivityDialog,
		cancelInvoiceDialog,
		convoDialog,
		invoicePreview,
		Payment
	},

	props: {
		invoice: {
			type: Object,
			default: () => ({})
		},

		invoiceId: {
			type: String,
			default: null
		},

		preview: {
			type: Boolean,
			default: false
		},

		convo : {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		panel: [0],
		xml: '',
		showXml: false,
		isPending: false,

		attachmentFile: null,
		definitiveStatus: false,

		mail: {},
		supplier: {},
		localInvoice: {
			ID: '',
			Amounts: {
				TaxExclusiveTotal: {
					Currency: ''
				}
			}
		},

		todos: [],

		showCodeTexts: JSON.parse(localStorage.getItem('kp-showcodetexts') || true),
		sensitivityLevels: ['Ingen', 'Utskrift', 'Visning'],
		showChangeSensitivityDialog: false,
		showCancelInvoiceDialog: false,
		showReviewDialog: false,
		showConvoDialog: false,
		previewOpen: false,
		paydateDialog : false,
		activeType: '',
		payDate: null,

		errorMessage: '',
		paydateError: '',
		isPendingReview: false
	}),

	computed: {
		...mapGetters('userStore', ['getUser', 'isUserAllowedToDeleteAccountedInvoice', 'isUserAllowedToInvoiceHandle']),

		amountLeft() {
			if (!this.localInvoice.ID) {
				return 0;
			}

			const accounted = this.localInvoice.meta.accounting.reduce((acc, curr) => {
				acc = acc + (Number(curr.amount) * 100);

				return acc;
			}, 0);
			const value = ((this.localInvoice.meta.amount.posting_total * 100) - accounted) / 100;

			return Number(value).toFixed(2);
		}
	},

	watch: {
		async 'invoice._id'() {
			this.definitiveStatus = false;
			await this.fetchInvoice();
			await this.fetchSupplier(this.localInvoice.meta.supplier_id);

			if(this.localInvoice.meta.status === 'Definitivt bokförd') {
				this.definitiveStatus = true;
				this.panel.push(2);
			}

			if (this.preview) {
				this.openPreview(this.localInvoice.meta.attachments[0].type);
			}
		},

		async invoiceId() {
			await this.fetchInvoice();
			await this.fetchSupplier(this.localInvoice.meta.supplier_id);

			if (this.preview) {
				this.openPreview(this.localInvoice.meta.attachments[0].type);
			}
		}
	},

	async created() {
		this.definitiveStatus = false;
		await this.fetchInvoice();
		await this.fetchSupplier(this.localInvoice.meta.supplier_id);
		this.getStatus();

		if(this.localInvoice.meta.status === 'Definitivt bokförd') {
			this.definitiveStatus = true;
			this.panel.push(2);
		}

		if (this.preview) {
			this.openPreview(this.localInvoice.meta.attachments[0].type);
		}

		if (this.convo) {
			this.showConvoDialog = true;
		}
	},

	methods: {
		async changePaydate() {
			this.paydateError = '';

			try {
				const localDate = this.$utils.date.dateToInt(this.payDate);

				await this.$service.invoices.setPaydate(this.invoice._id, localDate, {
					throwException: true
				});

				this.payDate = null;
				this.paydateDialog = false;
				this.fetchInvoice();
			} catch (error) {
				this.paydateError = error.message;
			}
		},

		setUpdatedConvo(item) {
			this.mail = {_id: item._id};
		},

		toggleShowCodeTexts() {
			this.showCodeTexts = !this.showCodeTexts;

			localStorage.setItem('kp-showcodetexts', this.showCodeTexts);
		},

		openPreview(type) {
			this.activeType = type;
			this.previewOpen = true;
		},

		closePreview() {
			this.previewOpen = false;
		},

		async completeAccounting() {
			run(await this.$service.invoices.accountingComplete.call({
				id: this.localInvoice._id
			}));
		},

		onStatusUpdate() {
			this.fetchInvoice();
			this.$emit('on-refetch');
		},

		async fetchInvoice() {
			this.errorMessage = '';

			try {
				const data = await this.$service.invoices.get(this.invoiceId || this.invoice._id, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.localInvoice = {
					...data,
					_underPayment: ['unpaid', 'blocked'].includes(data.meta.payment_status)
				};

			} catch(error) {
				this.errorMessage = error.message;
			}
			this.mail = {_id: this.localInvoice.meta.mail[0], meta: {invoice: this.localInvoice._id}};
		},

		async getStatus() {
			const data = await this.$service.invoices.getInvoiceStatus(this.localInvoice._id);

			this.todos = data;
		},

		async setReviewStatus() {
			this.errorMessage= '';
			this.isPendingReview = true;
			try {
				this.localInvoice.meta.review ?
					await this.$service.invoices.unreview({
						invoiceIds: [
							this.localInvoice._id
						]
					}) :
					await this.$service.invoices.review({
						invoiceIds: [
							this.localInvoice._id
						]
					});
				this.showReviewDialog = false;
			} catch(error) {
				this.errorMessage = error.message;
			}

			await this.fetchInvoice();
			this.isPendingReview = false;
		},

		async fetchSupplier(id) {
			if (id !== null) {
				this.supplier = await this.$service.suppliers.get(id);

				return;
			}

			this.supplier = {};
		},

		async getXml() {
			this.showXml = true;

			run(await this.$service.invoices.getXml.call({
				id: this.localInvoice._id
			}), d => {
				const strData = base64.decode(d.xml);
				const charData = strData.split('').map(x => x.charCodeAt(0));
				const binData = new Uint8Array(charData);
				const data = pako.inflate(binData, {to: 'string'});
				let final = data.substring(1, data.length-1);

				final = final
					.replaceAll('\\u00ef\\u00bb\\u00bf', '')
					.replaceAll('\\n', '\n')
					.replaceAll('\\t', '\t')
					.replaceAll('\\"', '"')
					.replaceAll('\\/', '/');

				this.xml = final;
			});
		}
	}
};
</script>

<style lang="scss">
	.InvoicePreview {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		background: var(--v-backgroundSecondary-base);
		width: calc(100% - 60%);
		z-index: 9;
	}

	.InvoicePreview-header {
		height: 64px;
		background: var(--v-background-base);

		.v-tab--active {
			background: var(--v-backgroundSecondary-base);
		}
	}

	.InvoicePreview-header-attchmentButtonWrapper {
		height: 100%;
		padding-right: 16px;
	}

	.Attest .v-text-field__details {
		display: none !important;
	}
</style>
