<template>
	<div class="pa-12">
		<Loader :error="errorMessage" :loading="isPending" />
		<v-form ref="form">
			<v-row>
				<v-col cols="3">
					<ui-autocomplete v-model="formData.unit"
						:items="newUserGroups"
						item-text="name"
						item-value="_id"
						label="Utbetalningsenhet *"
						@input="fetchPaymentGiros"
					>
						<template v-slot:item="{ item }">
							<span v-if="item._hasParent" :class="item._hasGrand ? 'ml-10' : 'ml-5'">{{ item.name }}</span>
							<span v-else>{{ item.name }}</span>
						</template>
					</ui-autocomplete>
				</v-col>
				<v-col cols="3">
					<search-supplier v-model="formData.supplier" clearable label="Leverantör" :rules="$validator('base/required')" @input="setSupplierData" />
				</v-col>
				<v-col cols="2">
					<label :style="`${formData.supplier ? '': 'color: 	#BEBEBE'}`" class="text-subtitle-2 font-weight-medium"> PDF {{ formData.supplier ? '*' : '' }} </label>
					<ui-file-input v-model="selectedFile" class="pdf-file-input" accept="application/pdf" prepend-inner-icon="mdi-upload" :rules="formData.supplier ? $validator('base/required'): []"
						:disabled="!formData.supplier"
						no-label @change="readFileAsBase64"
					/>
				</v-col>

				<v-col cols="2">
					<ui-text-field v-model.number="formData.amount" type="number" :rules="$validator('base/amount')" label="Belopp inkl. moms *" />
				</v-col>
				<v-col cols="2">
					<ui-text-field v-model.number="formData.vat_amount" type="number" :rules="$validator('base/amount')" label="Momsbelopp *" />
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="3">
					<label :style="`${formData.supplier ? '': 'color: 	#BEBEBE'}`" class="text-subtitle-2 font-weight-medium"> Fakturanr {{ formData.supplier ? '*' : '' }} </label>
					<ui-text-field v-model="formData.invoice_no" :rules="formData.supplier ? $validator('base/required'): []" :disabled="!formData.supplier" no-label />
				</v-col>
				<v-col cols="3">
					<div class="d-flex justify-space-between">
						<label class="text-subtitle-2 font-weight-medium "> Betalreferens/OCR-nummer *</label>
						<v-btn v-if="formData.supplier && formData.invoice_no && !formData.message " text x-small color="primary" class="font-weight-bold"
							@click="formData.message = formData.invoice_no;"
						>
							Kopiera fakturanr
						</v-btn>
					</div>
					<ui-text-field v-model="formData.message" clearable :rules="formData.message ? [] : $validator('base/required')" label="" no-label />
				</v-col>

				<v-col cols="2">
					<div class="d-flex justify-space-between">
						<div><label class="text-subtitle-2 font-weight-medium">Betaldatum *</label> </div>
						<div v-if="formData.supplier && suggestedDueDays && !formData.paydate " :rules="$validator('base/required')"><v-btn x-small color="primary" class="font-weight-bold" text @click="formData.paydate = suggestedDueDays">{{ suggestedDueDays }}</v-btn></div>
					</div>
					<ui-datepicker v-model="formData.paydate" clearable no-label />
				</v-col>
				<v-col cols="1" class="pr-0">
					<label :style="`${formData.supplier ? '': 'color: 	#BEBEBE'}`" class="text-subtitle-2 font-weight-medium"> Förfallodatum {{ formData.supplier ? '*' : '' }} </label>
					<ui-datepicker v-model="formData.duedate" class="pr-0" no-label :rules="!formData.supplier || formData.duedate ? [] : $validator('base/required')" :disabled="!formData.supplier"
						clearable
					/>
				</v-col>
				<v-col cols="1" class="pl-0">
					<label :style="`${formData.supplier ? '': 'color: 	#BEBEBE'}`" class="text-subtitle-2 font-weight-medium"> Fakturadatum {{ formData.supplier ? '*' : '' }} </label>
					<ui-datepicker v-model="formData.issue_date " no-label :rules="!formData.supplier || formData.issue_date ? [] : $validator('base/required')" :disabled="!formData.supplier" clearable />
				</v-col>
				<v-col v-if="paymentGiros" cols="2">
					<ui-autocomplete v-model="dnumber"
						:items="paymentGiros"
						label="Utbetalningsgiro *"
						return-object
						:rules="$validator('base/required')"
						@input="setPaymentGiro"
					/>
				</v-col>
			</v-row>

			<v-row>
				<v-col v-if="selectedSupplier" cols="3">
					<ui-autocomplete
						label="Utbetalas till *"
						:items="getSupplierGiros"
						return-object
						:rules="$validator('base/required')"
						@input="setPaidTo"
					/>
				</v-col>
				<v-col v-if="!selectedSupplier" cols="3">
					<ui-select v-model="selectedCBic"
						label="Utbetalas till *"
						:items="[
							{ text: 'Bankgiro', value: 'BGABSESS'},
							{ text: 'Plusgiro', value: 'PGSISESS'},
							{ text: 'Bankkonto', value: ''}
						]"
					/>
				</v-col>

				<v-col cols="3">
					<label :style="`${!selectedSupplier ? '': 'color: 	#BEBEBE'}`" class="text-subtitle-2 font-weight-medium"> Kontonr {{ !selectedSupplier ? '*' : '' }} </label>
					<ui-text-field v-model="formData.cnumber" no-label :rules="!selectedSupplier ? $validator('base/required'): []" :disabled="selectedSupplier ? true : false" />
				</v-col>

				<v-col cols="2">
					<ui-text-field v-if="selectedCBic == '' && !selectedSupplier" v-model="formData.cbic" :rules="$validator('base/required')" label="Clearingnr *" />
					<div v-else>
						<label style="color: #BEBEBE" class="text-subtitle-2 font-weight-medium"> Clearingnr</label>
						<ui-text-field disabled no-label />
					</div>
				</v-col>

				<v-col cols="2">
					<label :style="`${selectedSupplier ? '': 'color: 	#BEBEBE'}`" class="text-subtitle-2 font-weight-medium"> Referenskod {{ selectedSupplier ? '*' : '' }} </label>
					<search-reference v-model="formData.reference" label="" no-label :clearable="false" :disabled="selectedSupplier ? false : true"
						:rules="selectedSupplier ? $validator('base/required'): []"
					/>
				</v-col>
				<v-col cols="2">
					<label :style="`${!selectedSupplier ? '': 'color: 	#BEBEBE'}`" class="text-subtitle-2 font-weight-medium"> Grupp {{ selectedSupplier ? '' : '*' }} </label>
					<search-groups v-model="formData.group" label="" no-label :clearable="false" :disabled="selectedSupplier ? true : false"
						:rules="!selectedSupplier ? $validator('base/required'): []"
					/>
				</v-col>
			</v-row>

			<v-expansion-panels v-model="panel" multiple class="pt-6">
				<v-expansion-panel :disabled="!formData.amount">
					<v-expansion-panel-header class="text-button" style="line-height: 100%;">
						Kostnadskontering <span class="ml-4 text-caption">Kvar att kontera:
							<strong class="accent--text">{{ this.$utils.format.price(getAccountingAmountLeft) }}</strong>
						</span>
					</v-expansion-panel-header>
					<v-expansion-panel-content class="mx-8 my-12">
						<v-row>
							<v-col cols="3">
								<select-template @input="onTemplateInput" />
							</v-col>
						</v-row>
						<accounting-rows v-model="accountingAddData"
							:amount-left="getAccountingAmountLeft"
							@reset="accountingAddData = null"
							@add="addAccounting"
							@save="addAccounting"
							@delete="removeAccountingRow(accountingAddData)"
						/>

						<accounting-table v-if="formData.accounting.length > 0" class="my-12 pt-12"
							:accounting="formData.accounting"
							:attestants="formData.attestants"
							@on-click="item => { accountingAddData = item}"
						/>

						<div class="d-flex justify-end mt-2">
							<v-btn v-if="getAccountingAmountLeft == '0.00' && formData.amount" color="accent" text @click="showChooseAttestant = true">Välj attestanter</v-btn>
							<span v-if="getAccountingAmountLeft < 0" class="text-caption"><strong class="error--text"> Kontering överskriver belopp</strong></span>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>


				<v-expansion-panel :disabled="!formData.vat_amount">
					<v-expansion-panel-header class="text-button" style="line-height: 100%;">
						Momskontering <span class="ml-4 text-caption">Kvar att kontera:
							<strong class="accent--text">{{ this.$utils.format.price(getVatAccountingAmountLeft) }}</strong>
						</span>
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<v-row>
							<v-col cols="3">
								<select-template @input="onVatTemplateInput" />
							</v-col>
						</v-row>
						<accounting-rows v-model="vatAccountingAddData"
							:amount-left="getVatAccountingAmountLeft"
							:is-vat="true"
							@reset="vatAccountingAddData = null"
							@add="addVatAccounting"
							@save="addVatAccounting"
							@delete="removeAccountingRow(vatAccountingAddData, isVat)"
						/>

						<accounting-table v-if="formData.vat_accounting.length > 0" class="my-12"
							:is-vat="true"
							:accounting="formData.vat_accounting"
							:attestants="formData.vat_attestants"
							@on-click="item => { vatAccountingAddData = item}"
						/>

						<div class="d-flex justify-end mt-2">
							<v-btn v-if="getVatAccountingAmountLeft == '0.00' && formData.vat_amount" color="accent" text @click="showChooseVatAttestant = true">Välj attestanter</v-btn>
							<span v-if="getVatAccountingAmountLeft < 0" class="text-caption"><strong class="error--text"> Kontering överskriver momsbelopp</strong></span>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-form>

		<Loader :error="errorMessage" :loading="isPending" />

		<div class="text-right">
			<v-btn color="primary" text @click="onSubmit">
				Skicka
			</v-btn>
		</div>

		<choose-attestant v-if="showChooseAttestant"
			:group="formData.unit"
			:rows="formData.accounting"
			:sub-attestants="formData.attestants"
			@on-close="showChooseAttestant = false"
			@on-submit="onAttestantSubmit"
		/>

		<choose-attestant v-if="showChooseVatAttestant"
			:group="formData.unit"
			:rows="formData.vat_accounting"
			:sub-attestants="formData.vat_attestants"
			@on-close="showChooseVatAttestant = false"
			@on-submit="onAttestantSubmit($event, isVat)"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectTemplate from '@/components/select-template';
import AccountingRows from '@/components/accounting-rows';
import AccountingTable from './componenets/accounting-table';
import ChooseAttestant from './componenets/choose-attestant';
import SearchReference from '@/components/search/reference';


const defaultData = () => ({
	paydate: '',
	cnumber: null,
	dnumber: null,
	accounting: [],
	vat_accounting: [],
	attestants: [],
	vat_attestants: [],
	group: '',
	file: '',
	message: '',
	supplier: ''
});

export default {
	components: {
		AccountingRows,
		AccountingTable,
		ChooseAttestant,
		SelectTemplate,
		SearchReference
	},

	data: () => ({
		formData: {},

		panel: [],
		isVat: true,
		dnumber: null,

		accountingAddData: null,
		amountLeft: '0',
		showChooseAttestant: false,

		vatAccountingAddData: null,
		vatAmountLeft: '0',
		showChooseVatAttestant: false,

		suggestedDueDays: null,
		selectedCBic: 'BGABSESS',
		selectedSupplier: null,
		selectedFile: null,
		paymentGiros: null,

		isPending: false,
		errorMessage: []
	}),

	computed: {
		...mapGetters('userStore', ['getUserGroupId', 'getUserGroups']),

		getAccountingAmountLeft() {
			if (!this.formData.amount) {
				return 0;
			}

			let amountCount = 0;

			this.formData.accounting.forEach((acc) => {
				amountCount += parseInt(acc.amount);
			});

			return this.formData.amount - amountCount;
		},

		getVatAccountingAmountLeft() {
			if (!this.formData.vat_amount) {
				return 0;
			}

			let amountCount = 0;

			this.formData.vat_accounting.forEach((acc) => {
				amountCount += parseInt(acc.amount);
			});

			return this.formData.vat_amount - amountCount;
		},

		getSupplierGiros() {
			if(!this.selectedSupplier.giro) {
				return;
			}

			return this.selectedSupplier.giro.map((x) => {
				return {
					text: this.$utils.format.account({AccountNumber: x.number, Scheme: x.bic }),
					value: x.number
				};
			});
		},

		newUserGroups() {
			const items = this.getUserGroups.map(g => {
				if(g.parent) {
					const parent = this.getUserGroups.find(x => x._id === g.parent);

					if(parent.parent != null) {
						return {
							...g,
							_hasParent: g.parent != null ? true : false,
							_hasGrand: true
						};
					}

					return {
						...g,
						_hasParent: g.parent != null ? true : false
					};
				}

				return {
					...g,
					_hasParent: g.parent != null ? true : false
				};
			});

			return items;
		}
	},

	async created() {
		this.formData = defaultData();
		this.formData.unit = this.getUserGroupId;

		await this.fetchPaymentGiros(this.getUserGroupId);
	},

	methods: {
		addAccounting() {
			this.formData.attestants = {};
			this.accountingAddData._id = this.accountingAddData._id ? this.accountingAddData._id : `${this.formData.accounting.length}`;
			// eslint-disable-next-line no-unused-vars
			const {_id, ...formData } = this.accountingAddData;

			this.formData.accounting = [...this.formData.accounting.filter(x => x._id !== this.accountingAddData._id), {_id, ...formData }];
			this.accountingAddData = null;
		},

		addVatAccounting() {
			this.vatAccountingAddData._id = this.vatAccountingAddData._id ? this.vatAccountingAddData._id : `${this.formData.vat_accounting.length}`;
			// eslint-disable-next-line no-unused-vars
			const {_id, ...formData } = this.vatAccountingAddData;

			this.formData.vat_accounting = [...this.formData.vat_accounting.filter(x => x._id !== this.vatAccountingAddData._id), {_id, ...formData }];
			this.vatAccountingAddData = null;
		},

		onAttestantSubmit(item, isVat) {
			if(isVat) {
				this.formData.vat_attestants = item;
				this.showChooseVatAttestant = false;

				return;
			}

			this.formData.attestants = item;
			this.showChooseAttestant = false;
		},

		removeAccountingRow(row, isVat = false) {
			this.removeError = '';

			if(isVat) {
				this.formData.vat_accounting = this.formData.vat_accounting.filter(x => x._id !== row._id);

				return;
			}

			this.formData.accounting = this.formData.accounting.filter(x => x._id !== row._id);
		},

		onTemplateInput(value) {
			this.accountingAddData.codes = value ? value.codes.map(code => code) : [] ;
			this.accountingAddData._id =  value ? value._id : null;
		},

		onVatTemplateInput(value) {
			this.vatAccountingAddData.codes = value ? value.codes.map(code => code) : [] ;
			this.vatAccountingAddData._id =  value ? value._id : null;
		},

		async setSupplierData(id) {
			if(!id) {
				this.selectedSupplier = null;
				this.formData = {
					...this.formData,
					cbic: null,
					cnumber: null,
					reference: '',
					message: '',
					invoice_no: '',
					supplier: ''
				};

				return;
			}

			await this.fetchSupplier(id);

			this.formData.reference = this.selectedSupplier.default_reference;

			this.calculatePaydate(this.selectedSupplier);
		},

		async fetchSupplier(supplierId) {
			try {
				this.selectedSupplier = await this.$service.suppliers.get(supplierId, { throwException: true });
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		calculatePaydate(supplier) {
			const today = new Date();

			this.suggestedDueDays = supplier.duedays ? this.$utils.date.getFullDate(today.setDate(today.getDate() + supplier.duedays)): null;
		},

		setPaidTo(giro) {
			this.formData = {
				...this.formData,
				cbic: giro.bic,
				cnumber: giro.number
			};
		},

		setPaymentGiro(giro) {
			if(!giro) {
				this.getUserGroupId,
				this.dnumber = giro;
				this.formData.dnumber = giro;

				return;
			}

			this.formData = {
				...this.formData,
				dbic: 'BGABSESS',
				dnumber: this.$utils.format.onlyNumbers(giro)
			};
		},

		async fetchPaymentGiros(groupId) {
			if(!groupId) {
				this.errorMessage = 'Du måste välja aktiv grupp';

				return;
			}

			this.errorMessage = '';
			try {
				const settings =  await this.$service.settings.get('kiPayAccounts', groupId, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.paymentGiros = settings.value.map(value => this.$utils.format.giro('BG', value));
				this.dnumber = this.paymentGiros[0];
				this.setPaymentGiro(this.paymentGiros[0]);
			} catch (error) {
				this.errorMessage = error.message;
			}
		},

		validateAccounting() {
			if(!this.formData.attestants.length) {
				this.errorMessage.push('Det saknas attestanter för kostnadskontering');
			}

			if(!this.formData.vat_attestants.length) {
				this.errorMessage.push('Det saknas attestanter för momskontering');
			}

			if(this.getVatAccountingAmountLeft > 0 || this.getAccountingAmountLeft > 0) {
				this.errorMessage.push(`Belopp kvar att kontera:
				Kostnad (${this.$utils.format.price(this.getAccountingAmountLeft)})
				Moms (${this.$utils.format.price(this.getVatAccountingAmountLeft)})`);
			}

			if(this.errorMessage.length > 0) {
				return false;
			}

			return true;
		},

		async onSubmit() {
			this.errorMessage = [];

			if(!this.$refs.form.validate()) {
				return;
			}

			if(!this.validateAccounting()) {
				return;
			}

			const form = {
				...this.formData,
				duedate: this.formData.duedate ? this.$utils.date.dateToInt(this.formData.duedate) : null,
				issue_date : this.formData.issue_date  ? this.$utils.date.dateToInt(this.formData.issue_date) : null,
				paydate: this.$utils.date.dateToInt(this.formData.paydate),
				amount: parseFloat(this.formData.amount),
				vat_amount: parseFloat(this.formData.vat_amount),
				cnumber: this.formData.cnumber ? this.$utils.format.onlyNumbers(this.formData.cnumber) : '',
				cbic: this.selectedCBic ? this.selectedCBic : this.$utils.format.onlyNumbers(this.formData.cbic),
				attestants: this.formData.attestants.map(row => {
					return {
						...row,
						approvals: row.approvals.map(user => user.id),
						authorizations: row.authorizations.map(user => user.id)
					};
				}),
				vat_attestants: this.formData.vat_attestants.map(row => {
					return {
						...row,
						approvals: row.approvals.map(user => user.id),
						authorizations: row.authorizations.map(user => user.id)
					};
				})
			};

			try {
				await this.$service.payment.manual({
					...form
				}, {
					throwException: true,
					loading: val => {
						this.isPending = val;
					}
				});

				this.panel = [];
				this.formData = defaultData();
				this.resetForm();

			} catch(error) {
				this.errorMessage = error.message;
			}
		},

		async readFileAsBase64(file) {
			if(!file) {
				this.formData.attachment = null;

				return;
			}

			this.formData.attachment = await new Promise((resolve, reject) => {
				const reader = new FileReader();

				reader.onload = () => {
					resolve(reader.result);
				};

				reader.onerror = reject;
				reader.readAsDataURL(file);
			});
		},

		resetForm() {
			this.$refs.form.reset();
			this.$refs.form.resetValidation();
		}
	}
};
</script>

<style lang="scss">

.pdf-file-input {
	.v-input__prepend-outer {display: none;}
}

</style>
