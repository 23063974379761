<template>
	<div>
		<v-row class="d-flex">
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(0)"
					v-model="accountingFormData.A"
					clearable
					class="pr-2"
					item-value="intval"
					:items="getCompCodeList.A"
					:label="getCodeNames.A"
					:item-text="itemText"
					:hint="accountingFormData.A ? `${getCompCodeList.A.find(x => x.intval == accountingFormData.A).kommentar[0]}<br/>${getCompCodeList.A.find(x => x.intval == accountingFormData.A).kommentar[1]}` : ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(1)"
					v-model="accountingFormData.B"
					clearable
					class="pr-2"
					:items="getCompCodeList.B"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.B"
					:hint="accountingFormData.B ? `${getCompCodeList.B.find(x => x.intval == accountingFormData.B).kommentar[0]}<br/>${getCompCodeList.B.find(x => x.intval == accountingFormData.B).kommentar[1]}` : ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(2)"
					v-model="accountingFormData.C"
					clearable
					class="pr-2"
					:items="getCompCodeList.C"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.C"
					:hint="accountingFormData.C ? `${getCompCodeList.C.find(x => x.intval == accountingFormData.C).kommentar[0]}<br/>${getCompCodeList.C.find(x => x.intval == accountingFormData.C).kommentar[1]}` : ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(3)"
					v-model="accountingFormData.D"
					clearable
					class="pr-2"
					:items="getCompCodeList.D"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.D"
					:hint="accountingFormData.D ? `${getCompCodeList.D.find(x => x.intval == accountingFormData.D).kommentar[0]}<br/>${getCompCodeList.D.find(x => x.intval == accountingFormData.D).kommentar[1]}` : ''"
				/>
			</v-col>

			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(4)"
					v-model="accountingFormData.E"
					clearable
					class="pr-2"
					:items="getCompCodeList.E"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.E"
					:hint="accountingFormData.E ? `${getCompCodeList.E.find(x => x.intval == accountingFormData.E).kommentar[0]}<br/>${getCompCodeList.E.find(x => x.intval == accountingFormData.E).kommentar[1]}` : ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(5)"
					v-model="accountingFormData.F"
					clearable
					class="pr-2"
					:items="getCompCodeList.F"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.F"
					:hint="accountingFormData.F ? `${getCompCodeList.F.find(x => x.intval == accountingFormData.F).kommentar[0]}<br/>${getCompCodeList.F.find(x => x.intval == accountingFormData.F).kommentar[1]}`: ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(6)"
					v-model="accountingFormData.G"
					clearable
					class="pr-2"
					:items="getCompCodeList.G"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.G"
					:hint="accountingFormData.G ? `${getCompCodeList.G.find(x => x.intval == accountingFormData.G).kommentar[0]}<br/>${getCompCodeList.G.find(x => x.intval == accountingFormData.G).kommentar[1]}` : ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(7)"
					v-model="accountingFormData.H"
					clearable
					class="pr-2"
					:disabled="counterPart > 0"
					:items="getCompCodeList.H"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.H"
					:hint="accountingFormData.H ? `${getCompCodeList.H.find(x => x.intval == accountingFormData.H).kommentar[0]}<br/>${getCompCodeList.H.find(x => x.intval == accountingFormData.H).kommentar[1]}` : ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(8)"
					v-model="accountingFormData.I"
					clearable
					class="pr-2"
					:items="getCompCodeList.I"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.I"
					:hint="accountingFormData.I ? `${getCompCodeList.I.find(x => x.intval == accountingFormData.I).kommentar[0]}<br/>${getCompCodeList.I.find(x => x.intval == accountingFormData.I).kommentar[1]}` : ''"
				/>
			</v-col>
			<v-col cols="3">
				<ui-autocomplete v-if="getIsActiveCode(9)"
					v-model="accountingFormData.J"
					clearable
					class="pr-2"
					:items="getCompCodeList.J"
					item-value="intval"
					:item-text="itemText"
					:label="getCodeNames.J"
					:hint="accountingFormData.J ? `${getCompCodeList.J.find(x => x.intval == accountingFormData.J).kommentar[0]}<br/>${getCompCodeList.J.find(x => x.intval == accountingFormData.J).kommentar[1]}` : ''"
				/>
			</v-col>
		</v-row>

		<v-divider class="py-2" />

		<div class="d-flex">
			<v-menu v-model="bookingDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
				max-width="290px" min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<ui-text-field class="pr-2" :value="accountingFormData.date" readonly label="Bokföringsdatum" v-bind="attrs"
						v-on="on"
					/>
				</template>
				<v-date-picker v-model="accountingFormData.date" type="date" no-title scrollable />
			</v-menu>

			<v-menu
				v-model="dateMenu"
				:close-on-content-click="false"
				transition="scale-transition"
				offset-y
				max-width="290px"
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<ui-text-field
						:value="periodDisplay"
						class="pr-2"
						readonly
						dense
						v-bind="attrs"
						label="Periodisera"
						v-on="on"
					/>
				</template>
				<v-date-picker
					v-model="accountingFormData.period"
					type="month"
					no-title
					range
					scrollable
				/>
			</v-menu>

			<ui-text-field v-model="accountingFormData.amount" dense type="number"
				value=" "
				label="Belopp"
				class="pr-2"
			>
				<template v-slot:append>
					<v-btn icon small @click="setFullData"><v-icon small>mdi-auto-fix</v-icon></v-btn>
				</template>
			</ui-text-field>
		</div>

		<div class="d-flex">
			<v-alert dense color="primary" text class="d-flex justify-space-between mr-1">
				<span class="text-overline">
					{{ accountingFormData.date }}
				</span>
			</v-alert>
			<v-alert dense color="primary" text class="d-flex justify-space-between mr-1">
				<span class="text-overline">
					{{ getLenghtOfCode(0, `${ accountingFormData.A}`.length, 'A') }} {{ getLenghtOfCode(1, `${ accountingFormData.B}`.length, 'B') }}
					{{ getLenghtOfCode(2, `${ accountingFormData.C}`.length, 'C') }} {{ getLenghtOfCode(3, `${ accountingFormData.D}`.length, 'D') }}
					{{ getLenghtOfCode(4, `${ accountingFormData.E}`.length, 'E') }} {{ getLenghtOfCode(5, `${ accountingFormData.F}`.length, 'F') }}
					{{ getLenghtOfCode(6, `${ accountingFormData.G}`.length, 'G') }} {{ getLenghtOfCode(7, `${ accountingFormData.H}`.length, 'H') }}
					{{ getLenghtOfCode(8, `${ accountingFormData.I}`.length, 'I') }} {{ getLenghtOfCode(9, `${ accountingFormData.J}`.length, 'J') }}
				</span>
			</v-alert>
			<v-alert dense color="primary" text class="d-flex justify-space-between mr-1">
				<span class="text-overline">
					{{ accountingFormData.period[0] }} - {{ accountingFormData.period[1] }}
				</span>
			</v-alert>
			<v-alert dense color="primary" text class="d-flex justify-space-between">
				<span class="text-overline">
					{{ accountingFormData.amount }}
				</span>
			</v-alert>
		</div>


		<div class="d-flex align-end justify-end mt-6">
			<v-btn v-if="!readOnly" color="primary" text @click="saveDialog = true">Spara som mall</v-btn>
			<v-btn text @click="noteOpen = true">
				<v-icon v-if="accountingFormData.note" left small>mdi-note-outline</v-icon>
				Anteckning
			</v-btn>


			<v-btn v-if="!accountingFormData._id && !readOnly" color="accent" :disabled="parseFloat(accountingFormData.amount) === 0 || isNaN(parseFloat(accountingFormData.amount))" text @click="add">Lägg till</v-btn>
			<v-btn v-if="accountingFormData._id && !readOnly" color="warning" text @click="reset">Avbryt</v-btn>
			<v-btn v-if="accountingFormData._id && !readOnly" color="error" text @click="deleteAccounting">Ta bort</v-btn>
			<v-btn v-if="accountingFormData._id && !readOnly" color="accent" :disabled="parseFloat(accountingFormData.amount) === 0 || isNaN(parseFloat(accountingFormData.amount))" text @click="save">Spara</v-btn>
		</div>

		<Dialog v-if="noteOpen" title="Anteckning" @confirm="noteOpen = false" @reject="noteOpen = false">
			<ui-textarea v-model="accountingFormData.note" label="Anteckning" />
		</Dialog>

		<Dialog v-if="saveDialog" :loading="isAddPending" title="Privat konteringsmall" @confirm="onConfirmTemplate" @reject="saveDialog = false">
			<ui-text-field v-model="template.name" label="Namn på konteringmall" />
		</Dialog>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import { intToDate, getFullDate } from '@/utils/date';

const defaultData = () => ({
	_id: null,
	codes: [null, null, null, null, null, null, null, null, null, null],
	period_length: null,
	period_start: null,
	amount: '',
	note: '',
	date: null
});

export default {
	props: {
		showCodeTexts: {
			type: Boolean,
			default: true
		},
		value: {
			type: Object,
			default: defaultData()
		},
		error: {
			type: String,
			default: ''
		},
		success: {
			type: String,
			default: ''
		},
		date: {
			type: Number,
			default: 0
		},
		readOnly: {
			type: Boolean,
			default: false
		},
		amountLeft: {
			type: String,
			default: '0'
		},
		counterPart: {
			type: Number,
			default: null
		},
		templateId: {
			type: String,
			default: null
		}
	},

	data: () => ({
		localData: {},
		localCounter: null,
		updateFeedbackMessage: null,
		updateError: '',

		noteOpen: false,

		dateMenu: false,
		buttonClicked: false,
		bookingDateMenu: false,

		saveDialog: false,
		template: {
			uid: null,
			name: '',
			codes: []
		},
		isAddPending: false,

		accountingFormData: {
			A: null,
			B: null,
			C: null,
			D: null,
			E: null,
			F: null,
			G: null,
			H: null,
			I: null,
			J: null,
			period: ['', ''],
			amount: '',
			note: '',
			date: '',
			comment: ''
		}
	}),

	computed: {
		...mapGetters('accounting', ['getCodelist', 'getCodeNames', 'getCodeLengths', 'getIsActiveCode']),
		...mapGetters('userStore', ['getUser', 'getCodePermissions']),

		periodDisplay() {
			const p = [...this.accountingFormData.period];

			p.sort();

			return p.length === 0 ? '' : `${p[0] || ''} - ${p[1] || ''}`;
		},

		getCompCodeList() {
			const prem = Object.entries(this.getCodelist).reduce((acc, [key, value]) => {
				return {
					...acc,
					[key]:  value.filter(code => this.checkPremission(key, code.intval)).length > 0
						? value.filter(code => this.checkPremission(key, code.intval)) : acc[key]
				};
			}, this.getCodelist);

			return  prem;
		}
		// isButtonClickable() {
		// }
	},

	watch: {
		'value._id'() {
			this.setData();
		},

		'accountingFormData.amount'() {
			this.updateTotalAmount();
		},

		templateId() {
			this.setData();
		},

		counterPart() {
			this.setData();
		},

		accountingFormData: {
			deep: true,

			handler() {
				this.$emit('input', {
					_id: this.value ? this.value._id : null,
					codes: [
						this.accountingFormData.A,
						this.accountingFormData.B,
						this.accountingFormData.C,
						this.accountingFormData.D,
						this.accountingFormData.E,
						this.accountingFormData.F,
						this.accountingFormData.G,
						this.accountingFormData.H,
						this.accountingFormData.I,
						this.accountingFormData.J
					],
					date: Number(String(this.accountingFormData.date).replaceAll('-', '')),
					amount: this.accountingFormData.amount,
					period_start: Number(this.accountingFormData.period[0].replaceAll('-', '')),
					period_length: Number(this.$utils.date.monthDiff(new Date(this.accountingFormData.period[0]), new Date(this.accountingFormData.period[1]))) + 1,
					note: this.accountingFormData.note
				});
			}
		}
	},

	created() {
		this.setData();
	},

	methods: {
		checkPremission(key, value) {
			const perm = this.getCodePermissions[key];
			let isOk = false;

			perm.forEach(range => {
				if (value >= range[0] && value <= range[1]) {
					isOk = true;
				}
			});

			return isOk;
		},

		// updateTotalAmount() {
		// 	const numericBase = parseFloat(this.accountingFormData.amount);

		// 	if (!isNaN(numericBase)) {
		// 		const total = numericBase + this.amountLeft;

		// 		this.accountingFormData.amount = total.toFixed(2);
		// 	}
		// },

		setFullData() {
			const numericLeftToAdd = parseFloat(this.amountLeft);

			this.accountingFormData.amount = numericLeftToAdd.toFixed(2);
			// if (!this.buttonClicked) {
			// 	const numericBase = parseFloat(this.accountingFormData.amount);
			// 	const numericLeftToAdd = parseInt(this.amountLeft);

			// 	if (!isNaN(numericBase) && numericBase !== 0) {
			// 		this.accountingFormData.amount = (numericBase + numericLeftToAdd).toFixed(2);
			// 	} else {
			// 		this.accountingFormData.amount = numericLeftToAdd.toFixed(2);
			// 	}

			// 	this.buttonClicked = true;
			// }
		},


		setData() {
			const value = this.value ? this.value : defaultData();
			const counter = this.counterPart > 0 ? this.counterPart : null;
			const date = value.date ?
				getFullDate(intToDate(value.date)) :
				getFullDate(new Date());

			this.accountingFormData = {
				_id: value._id,
				A: value.codes[0],
				B: value.codes[1],
				C: value.codes[2],
				D: value.codes[3],
				E: value.codes[4],
				F: value.codes[5],
				G: value.codes[7],
				H: counter,
				I: value.codes[8],
				J: value.codes[9],
				period: (!value.period_start || !value.period_length) ? ['', ''] : this.periodReverse(value.period_start, value.period_length),
				amount: value.amount,
				note: value.note,
				date
			};
		},

		async onConfirmTemplate() {
			try{
				await this.$service.codestrings.create({
					...this.template,
					uid: this.getUser.user.id,
					codes: this.value.codes.map(code => code ? parseInt(code) : 0)
				}, {
					loading: val => {
						this.isAddPending = val;
					},
					throwException: true
				});

				this.saveDialog = false;
				this.template = null;
				this.updateFeedbackMessage = ['Sparad'];
				this.$emit('template', this.updateFeedbackMessage);
			} catch (error) {
				this.error = error.message;
			}
		},

		periodReverse(start, length) {
			const d = this.$utils.date.addMonths(intToDate(`${start}01`), length);
			const endDate = `${this.$utils.date.getYear(d)}-${this.$utils.date.getMonth(d)}`;
			const s = String(start);

			return [`${s.substring(0, 4)}-${s.substring(4, 6)}`, endDate];
		},

		itemText(a) {
			return this.showCodeTexts ? `${a.kod} - ${a.kodtext}` : a.kod;
		},

		getLenghtOfCode(codeIndex, codeLength, code) {
			const length = this.getCodeLengths[codeIndex] - codeLength;

			if(!this.accountingFormData[code]) {
				return this.getCodeLengths[codeIndex] === 0 ? '' : '-';
			}

			return `${'0'.repeat(length)}${ this.accountingFormData[code] } -`;
		},

		add() {
			this.$emit('add');
		},

		reset() {
			this.$emit('reset');
			this.updateFeedbackMessage = ['Kontering återställd'];
		},

		deleteAccounting() {
			this.$emit('delete');
			this.updateFeedbackMessage = ['Kontering borttagen'];
		},

		save() {
			this.$emit('save');
		}
	}
};
</script>
